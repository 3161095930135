import img01 from "@content/tutorial/images/Bricklayer-amico.svg";
import img02 from "@content/tutorial/images/Completed steps-amico.svg";

import { PageLayout } from "@components";
import {
  TutorialSelector,
  TutorialSelectorItem,
} from "@components/TutorialSelector";

import "@styles/SelectionPage.scss";

export default function TutorialsIndex() {
  return (
    <PageLayout title="Tutorials" defaultBodyClassName="features-root">
      <TutorialSelector>
        <TutorialSelectorItem
          href="/en/tutorial/a-to-z/"
          src={img01}
          title="Designing a Study from A to Z"
        >
          <p>
            This tutorial is aimed at users looking to set up a study and build
            an eCRF from scratch.
          </p>
        </TutorialSelectorItem>

        <TutorialSelectorItem
          href="/en/tutorial/using-dotter/"
          src={img02}
          title="Using Dotter.science in Daily Life"
        >
          <p>
            If you are a user of an already created study, this tutorial will
            teach you how to use the established eCRF.
          </p>
        </TutorialSelectorItem>
      </TutorialSelector>
    </PageLayout>
  );
}
